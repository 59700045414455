import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RUTformatPipe } from './pipes/rutformat-pipe.pipe';
import { DialogPdfviewerComponent } from './components/dialog-pdfviewer/dialog-pdfviewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PdfViewerModule,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RUTformatPipe,
        Ng2SearchPipeModule
    ],
    declarations: [
      RUTformatPipe,
      DialogPdfviewerComponent
    ]
})
export class SharedModule
{
}

