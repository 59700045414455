import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  appToken: string = localStorage.getItem('appToken');//environment.appTokenUser;

  constructor(
    private _http: HttpClient
  ) { 
    // const emisorData = JSON.parse(localStorage.getItem('emisorData'));
    // console.log(emisorData.emisorData[0].UUID_EMISOR)
    // console.log(this.appToken)
    // this.appToken = emisorData.emisorData[0].UUID_EMISOR
  }

  async getCatalog(): Promise<any>{
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const day = new Date().getDate();
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getAllCatalog}${$this.appToken}/ALL/${year}/${month}/${day}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
            // console.log(data);
            data = data.dataSet;
            data.data.forEach((element: any) => {
              // console.log(element)
              element.J_ANTICIPADO_TAX = JSON.parse(element.J_ANTICIPADO_TAX);
              element.J_RETENIDO_TAX = JSON.parse(element.J_RETENIDO_TAX);
              element.J_TAX = JSON.parse(element.J_TAX);
              element.PRICE = JSON.parse(element.PRICE);
            });
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  async chkDocs(data: any, auth: any): Promise<any> {
    // console.log(data)
    return await this._http.post<any>( environment.endPoints.chkDTE, {
      'auth': JSON.stringify(auth),
      'data': JSON.stringify(data)
    });
  }

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getCategorias(type? : boolean): Promise<any>{
  const $this = this;
  $this.appToken = localStorage.getItem('appToken');
  var url;
  return await new Promise (async function(resolve, reject) {
    try {
      if(type == true)
          url = await $this._http.get(`${environment.endPoints.categorias}${$this.appToken}/Y`);
      else
          url = await $this._http.get(`${environment.endPoints.categorias}${$this.appToken}`);
      url.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// Obtener familias del catalogo
async getGroups(type? : boolean): Promise<any>{
  const $this = this;
  $this.appToken = localStorage.getItem('appToken');
  var url;
  return await new Promise (async function(resolve, reject) {
    try {
      if(type == true)
          url = await $this._http.get(`${environment.endPoints.groups}${$this.appToken}/Y`);
      else
          url = await $this._http.get(`${environment.endPoints.groups}${$this.appToken}`);
      url.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getCurrency(month: number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const year = new Date().getFullYear();
  return await new Promise (async function(resolve, reject) {
    try {
      const res = await $this._http.get<any>(`${environment.endPoints.currency}${$this.appToken}/${year}/${month}`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
}