/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'Inicio',
        title: 'Punto de Venta',
        type : 'basic',
        icon : 'heroicons_outline:shopping-cart',
        link : '/home',
    },
    {
        id: 'Orders',
        title: 'Ordenes',
        type: 'basic',
        icon : 'heroicons_outline:shopping-bag',
        link : '/ordenes',
    },       
    {
        id   : 'MovementsSP',
        title: 'Movimientos',
        type : 'basic',
        icon : 'heroicons_outline:calculator',
        link : '/movementsSP',
    },      
    {
        id   : 'Clientes',
        title: 'Clientes',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/clientes',
    },   
    {
        id   : 'cupones',
        title: 'Cupones',
        type : 'basic',
        icon : 'heroicons_outline:receipt-tax',
        link : '/discounts',
    },         
    {
        id   : 'Config',
        title: 'Impresora',
        type : 'basic',
        icon : 'heroicons_outline:printer',
        link : '/printer',
    }
];
