/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-dialog-pdfviewer',
  templateUrl: './dialog-pdfviewer.component.html',
  styleUrls: ['./dialog-pdfviewer.component.scss']
})
export class DialogPdfviewerComponent implements OnInit {

  urlPDF = '';
  isLoading?: boolean = true;
  // @ViewChild('pdf') pdfViewer: any;
  constructor(
    public dialogRef: MatDialogRef<DialogPdfviewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if(this.data.typeDoc === 'DTE'){
      this.urlPDF = environment.endPoints.pdf + this.data.uuid;
    }else if(this.data.typeDoc === 'BOL'){
      this.urlPDF = environment.endPoints.pdf + this.data.uuid;
    }else if(this.data.typeDoc === 'TKT'){
      this.urlPDF = environment.endPoints.pdfTkt + this.data.uuid;
    }else if(this.data.typeDoc === 'CTZ'){
      this.urlPDF = environment.endPoints.pdfCtz + this.data.uuid;
    } else if(this.data.typeDoc === 'DTEEXT'){
      this.urlPDF = environment.endPoints.pdfExt + this.data.uuid;
    }

  }

  ngOnInit(): void {

  }

  close(){
    this.dialogRef.close();
  }

  finishSpinner(): void {
    this.isLoading = false;
  }

}
