import { Component } from '@angular/core';
//import { PrinterService } from './services/printer.service'; 

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */

    constructor() 
    {
    }
}
