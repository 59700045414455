<div class="close" (click)="close()">
    <button mat-button> <mat-icon>close</mat-icon></button>
  </div>
<mat-spinner *ngIf="isLoading" style="margin:0 auto auto auto"></mat-spinner>
<pdf-viewer 
    #pdf
    [src]="urlPDF" 
    [render-text]="true"
    [original-size]="true"
    (after-load-complete)="finishSpinner()"
    style="width: 800px; height: 1000px"
></pdf-viewer>