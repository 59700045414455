import { Pipe, PipeTransform } from '@angular/core';
import * as rut from '@fdograph/rut-utilities';

@Pipe({
  name: 'rut'
})
export class RUTformatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const rutValue = value.toString();
    if(value){
      if(args.includes('DASH')){
        return rut.formatRut(rutValue, rut.RutFormat.DASH);
      };
      if(args.includes('DOTS')){
        return rut.formatRut(rutValue, rut.RutFormat.DOTS);
      };
      if(args.includes('DOTS_DASH')){
        if(value === '55555555-5' || value === '66666666-6' || value === '77777777-7' || value === '88888888-8' || value === '99999999-9' || value === '00000000-0'){
          return 'Anonimo';
        }else{
            return rut.formatRut(rutValue, rut.RutFormat.DOTS_DASH);
        }
      }

    }
    return null;
  }

}
